import React from 'react';
import Moment from 'moment';

import Layout from 'components/layout';
import LegalLayout from 'components/legal-layout';
import SEO from 'components/seo';
import TermsAndConditionsEN from '@youship/legal/terms-en';
import TermsAndConditionsPT from '@youship/legal/terms-pt';


const lastModifiedDate = Moment().format('MMMM DD, YYYY');

const TermsAndConditionsPage = ({ pageContext: { locale } }) => {
  let termsContent = null;

  switch (locale) {
    case 'pt':
      termsContent = <TermsAndConditionsPT />;
      break;
    case 'en':
    default:
      termsContent = <TermsAndConditionsEN />;
  }

  return (
    <Layout locale={locale}>
      <SEO title="terms.title" />
      <LegalLayout
        lastModifiedDate={lastModifiedDate}
        title="terms.title"
      >
        {termsContent}
      </LegalLayout>
    </Layout>
  );
};

export default TermsAndConditionsPage;
